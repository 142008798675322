@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
body {
    background-image:url("2331.jpg");
    height:100vh;
    background-repeat:no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: 40%;
    padding: 0px;
    margin: 0;
    font-family:"Montserrat", sans-serif;
  }
  h1, h2, p, ul, li {
    font-family: 'Montserrat', sans-serif;
  }
  ul.header li {
    display: inline;
    list-style-type: none;
    margin: 0;
  }
  ul.header {
    background-color:#F8F9FA;
    padding: 0;
    margin:0;
  }
  ul.header li a {
    color: #000;
    font-weight: bold;
    text-decoration: none;
    padding: 20px;
    display: inline-block;
  }
  .content {
    background-color: rgba(100, 100, 100,0.8);
    padding: 20px;
    color:#fff;
    vertical-align: middle;
    text-align:center;
    
  }
  .content h1 {
    padding: 0;
    margin: 0;
  }
  .content li {
    margin-bottom: 10px;
  }

  .active {
      background-color: #0099FF;
  }
    .playerDiv{
    top: 0;
    display: flex;
    height: 64px;
    position: fixed;
    right: 58px;
    width: 50%;
    z-index: 1500;
    overflow: hidden;
  }

  @media (max-width:500px) {
    .playerDiv{
      position:fixed;
      top:0;
      width:100%;
    }
    .playerDiv iframe {
      position:fixed;
      top:-92px;
      right:0;
    }
  }
  
/*These declarations are needed to vertically centre the content on the page*/
.outer {
  display: table;
  position: absolute;
  top: 60px;
  left: 0;
  height: calc(100% - 60px);
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /*whatever width you want*/
}


.corner-triangle {
  display: block;
  cursor: pointer;
  width: 58px;
  height: 64px;
  border-style: solid;
  border-color:  #da0039 ; /* adjust for color of triangle */
  border:0;
  background-color: #da0039;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999;
  color: white;
  text-shadow: 0 0 25px 9px #fff;
  -webkit-filter: drop-shadow(0 1px 9px #000000);
  filter: drop-shadow(0 1px 9px #000000);
}

  @media (max-width:500px){
    .corner-triangle{
    display:none;
    z-index:-1;
    }
  }
.corner-triangle .corner-triangle-text {
  position: relative;
  font-size: 2.1em;
  top: 0;
  right: 0px;
  font-family: sans-serif, "Helvetica Neue", Helvetica, Arial;
  font-weight: 200;
  line-height: 1.1;
}
.corner-triangle .corner-triangle-text .fa-caret-right {
  transform:scale(1.5) rotate(0deg);
  text-align:center;
  position:fixed;
  top:14px;
  right:16px;
}
.corner-triangle .corner-triangle-text a {
  color: white;
}
.corner-triangle .corner-triangle-text a:hover,
.corner-triangle .corner-triangle-text a:link,
.corner-triangle .corner-triangle-text a:visited,
.corner-triangle .corner-triangle-text a:active,
.corner-triangle .corner-triangle-text a:focus {
  text-decoration: none;
}

@keyframes caretretract {
  from {transform:scale(1.5) rotate(0deg);}
  to {transform:scale(1.5) rotate(180deg);}
}

@keyframes caretexpand {
  from {transform:scale(1.5) rotate(180deg);}
  to {transform:scale(1.5) rotate(0deg);}
}

@keyframes playerretract {
  from {right:58px}
  to {right:calc(-50% + 116px)}
}

@keyframes playerexpand {
  from {right:calc(-50% + 116px)}
  to {right:58px}
}

.srlogo{
  height:25px;
  position:relative;
  top:13px;
  margin-right:10px;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}
.large-menu-classname{
  text-align: left !important;
}

.small-menu-classname{
  background-color:#F8F9FA;
  height:58px;
  position:fixed;
  top:58px;
  width:100%;
  /*text-align:left;*/
}

.fa-bars, .fa-times{
  height:58px;
  width:30px !important;
}

@media (max-width: 500px) {
  .outer {
    height: calc(100% - 180px);
    top:180px;
  }
}